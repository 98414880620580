<template>
  <v-container grid-list-lg>
    <v-layout row wrap v-if="loadingCategories">
      <v-flex xs12 sm6 lg3 v-for="n in 3" :key="n">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="category-products mb-10 mt-10 justify-center">
      <v-flex xs12 sm6 md6 lg4 v-for="product in allProducts" :key="product.id">
        <v-card flat class="text-center transparent">
          <h3 class="product-title mb-2" @click="$router.push('/product/' + product.alias)">
            {{ product.name }}
          </h3>
          <div>
            <v-img class="cursor-pointer" :src="getProductImage(product.imageUrl)"
              @click="$router.push('/product/' + product.alias)">
              <v-container style="width: 90%;margin-top: 35%;">
                <v-row>
                  <v-col>
                    <h2 class="white--text font-weight-bold">
                      {{
                        $t("homepage.currency", {
                          price: formatThousands(product.amountWithVat)
                        })
                      }}
                    </h2>
                  </v-col>
                  <v-col style="display: flex; align-items:center;">
                    <div class="white--text" style="vertical-align: middle;text-transform: lowercase;">{{
                      $t("cart.addToCart") }}</div>
                    <img style="height: 16px; line-height: 16px;" class="cart-image ml-1"
                        :src="require('../../assets/shoping_bag_white.svg')" />
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </div>
          <v-card-text class="description pt-2">
            <strong v-html="product.shortDescription"></strong>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getProductImage } from "@/Helper";
import { mapGetters, mapActions } from "vuex";
import { formatThousands } from "@/Helper";

export default {
  name: "Products",
  mixins: [formatThousands, getProductImage],
  computed: {
    ...mapGetters(["allProducts", "loadingCategories"]),
    fontHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'font-size:20pt'
        case 'sm': return 'font-size:16pt'
        case 'md': return 'font-size:12pt'
        case 'lg': return 'font-size:16pt'
        case 'xl': return 'font-size:18pt'
      }
      return '';
    },
    fontHeightCart() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '9pt'
        case 'sm': return '9pt'
        case 'md': return '7pt'
        case 'lg': return '10pt'
        case 'xl': return '10pt'
      }
      return '';
    }
  },
  watch: {
    allProducts: function (newValue) {
      if (newValue) {
        if (this.$gtm.enabled()) {
          let products = [];
          for (let i in this.allProducts) {
            products.push({
              id: this.allProducts[i].productId,
              name: this.allProducts[i].name,
              price: this.allProducts[i].amountWithVat,
              category: this.allProducts[i].productCategoryName,
              list: "Home Page",
              position: i + 1,
            });
          }
          this.$gtm.trackEvent({
            event: "productImpression", // Event type [default = 'interaction'] (Optional)
            category: "Ecommerce",
            ecommerce: {
              impressions: products,
            },
          });
        }
      }
    },
  },
  methods: {
    ...mapActions(["addToCart"]),
  },
};
</script>

<style lang="scss">
.category-products {
  .cart-button {
    text-transform: initial;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .cart-image {
    vertical-align: middle;
  }

  .price {
    strong {
      display: inline-flex;
      margin-top: 5px;
      font-size: 13px;
    }
  }

  .product-title {
    text-transform: uppercase;
    color: #262262;
    line-height: 110%;
  }

  .description {
    color: #262262 !important;

    p {
      margin-bottom: 0 !important;
    }
  }
}
</style>
