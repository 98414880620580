<template>
  <v-skeleton-loader type="actions" v-if="loadingCategories || !currentProduct"></v-skeleton-loader>
  <div v-else>

    <v-container fluid class="text-center py-0 pl-0 pr-0 pb-0">
      <div class="text-center main-title">
        <h1>{{ title }}</h1>
      </div>
    </v-container>

    <v-container grid-list-lg class="mt-3">
      <v-layout row wrap>
        <v-flex xs12 sm5></v-flex>
        <v-flex xs12 sm7>
          <v-toolbar flat dense class="navigation">
            <v-btn small text @click="backButton" class="pl-0">
              <v-icon left>mdi-chevron-left</v-icon>
              {{ $t("product.backToCategory") }}
            </v-btn>
          </v-toolbar>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 sm5>
          <v-skeleton-loader type="image" v-if="loadingCategories"></v-skeleton-loader>
          <div class="product-title-container">
            <v-img :src="getProductImage(imageData)">
              <div class="text-center" style="width: 100%;margin-top:40%;">
                <h1 class="white--text">{{
                  $t("homepage.currency", {
                    price: formatThousands(currentProduct.amountWithVat)
                  })
                }}</h1>
              </div>
            </v-img>
          </div>
        </v-flex>
        <v-flex xs12 sm7>
          <product-detail />
        </v-flex>
        <v-flex cols="12" md="6" class="text-center mt-5 mb-5" style="color:#ee2a7b">
           <h2>&#9660; {{ $t('product.validHere') }} &#9660;</h2>
          <v-container>
            <v-row v-for="subItem in currentProduct.subItems" :key="subItem.id">
              <v-col xs="12" sm="2" md="2"><v-img :class="{half:  $vuetify.breakpoint.xsOnly }" :src="subItem.imageUrl"></v-img></v-col>
              <v-col xs="12" sm="10" md="10">
                <div class="mb-1 text-left"><v-chip color="primary" size="x-small">{{ $t('product.includedInPrice') }}</v-chip></div>
                <div class="headline mb-1 text-left" style="color:#ee2a7b">{{ subItem.shortDescription }}</div>
                <div class="text-left" style="color:#262262" v-html="subItem.description"></div>
              </v-col>
            </v-row>
          </v-container>
          <!--           <h2>&#9660; {{ $t('product.validHere') }} &#9660;</h2>
          <v-card class="mx-auto" outlined color="transparent" v-for="subItem in currentProduct.subItems"
            :key="subItem.id">
            <v-list-item>
              <v-list-item-avatar tile size="120" color="grey">
                <v-img :src="subItem.imageUrl"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <div class="mb-1 text-left"><v-chip color="primary" size="x-small">Vstup v ceně</v-chip></div>
                <v-list-item-title class="headline mb-1 text-left" style="color:#ee2a7b">{{ subItem.shortDescription
                }}</v-list-item-title>
                <div class="text-left" v-html="subItem.description"></div>
              </v-list-item-content>
            </v-list-item>
          </v-card> -->
        </v-flex>

      </v-layout>
    </v-container>


  </div>
</template>

<script>
import ProductDetail from "../components/Product/ProductDetail";
import { getProductImage } from "@/Helper";
import { mapActions, mapGetters } from "vuex";
import { formatThousands } from "@/Helper";

export default {
  name: "ProductPage",
  metaInfo() {
    return {
      title: this.currentProduct ? this.currentProduct.name : "",
    };
  },
  mixins: [formatThousands, getProductImage],
  components: {
    ProductDetail,
  },
  computed: {
    ...mapGetters(["currentCategory", "allProducts", "currentProduct", "loadingCategories"]),
    imageData() {
      if (this.currentProduct) {
        return this.currentProduct.imageUrl;
      }

      return null;
    },
    title() {
      if (this.currentCategory) {
        return this.currentCategory.name;
      }

      return "Obchod";
    },
    titleColor() {
      if (this.currentCategory && this.currentCategory.customData && this.currentCategory.customData.backgroundColor) {
        return this.currentCategory.customData.backgroundColor;
      }

      return "#9b9c9c";
    },
  },
  watch: {
    currentProduct(currentProd) {
      if (currentProd) {
        this.changeCategory({ categoryId: currentProd.productCategoryId, langChange: false });
      }
    }
  },
  mounted() {
    let productAlias = this.$router.currentRoute.params;
    if (typeof productAlias.alias !== "undefined") {
      this.changeProduct({ langChange: false });
    }
  },
  methods: {
    ...mapActions(["changeProduct", "changeCategory"]),
    backButton() {
      if (this.currentCategory) {
        this.$router.push("/category/" + this.currentCategory.alias);
        return;
      }
      this.$router.push("/");
    }
  },
};
</script>
<style lang="scss">
.main-title {
  background-color: #2f63af;
  display: table;
  height: 80px;
  width: 100%;
  margin-top: 0;
}

.main-title h1 {
  color: white;
  display: table-cell;
  vertical-align: middle;
  font-size: 26pt;
  line-height: 26pt;
}

.navigation .v-toolbar__content {
  padding-left: 0px;
}

.v-chip.v-size--default {
  height: unset !important;
  padding-top: 1px;
  padding-bottom: 1px;
}
.half{
  max-width: 50%;
}
</style>

