<template>
    
  <v-container fluid class="text-center py-0 pl-0 pr-0 pb-0">
<div>
    <img width="100%" style="vertical-align: middle;" :src="bannerImage"/></div>
   
      <div class="text-center main-title"><h1>{{ title }}</h1></div>
    
   
  </v-container>
      
<!--       <div v-if="showCategories">
        <v-btn
          @click="$router.push('/category/' + category.alias)"
          text
          :color="getButtonColor(category.alias)"
          v-for="category in allCategories"
          :key="category.id"
          >{{ category.name }}</v-btn
        >
      </div>
 --> 
<!--  <v-container>
    <div class="text-center main-description" v-if="description" v-html="description"></div>
  </v-container> -->

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getProductImage } from "@/Helper";

export default {
  name: "CategoryHeader",
  props: ["showCategories"],
  mixins: [getProductImage],
  computed: {
    ...mapGetters(["currentCategory", "allCategories"]),
    title() {
      if (this.currentCategory) {
        return this.currentCategory.name;
      }

      return "Obchod";
    },
    description() {
      if (this.currentCategory) {
        return this.currentCategory.description;
      }

      return null;
    },
    imageUrl() {
      if (this.currentCategory) {
        return this.currentCategory.imageUrl;
      }

      return null;
    },
    bannerImage() {
      if (this.currentCategory && this.currentCategory.customData && this.currentCategory.customData.banner) {
        return  require(`@/assets/banners/${this.currentCategory.customData.banner}`);
      }
      return null;
    },
    activeItem() {
      if (this.currentCategory) {
        return this.currentCategory.alias;
      }

      return null;
    },
  },

  methods: {
    ...mapActions(["changeCategory"]),
    getButtonColor(alias) {
      if (alias === this.activeItem) {
        return "primary";
      }

      return "black";
    },
  },
};
</script>

<style lang="scss">
 .main-title {      
  background-color: #2f63af;
    display:table;      
    height: 80px;
    width:100%;     
    margin-top: 0;
  }  
  .main-title  h1{      
    color: white;
    display:table-cell; 
    vertical-align:middle;
    font-size: 26pt;
    line-height: 26pt;
  }

 /*  .main-title {
  
    font-size: 18pt;
    line-height: 18pt;
    color: white;    
    display:table;      
    height: 80px;
    width:100%;     
    margin-top: 0;
  } */
  // .main-title h1{      
  //   font-weight: 600;
  //   color: white;
  //   display:table-cell; 
  //   vertical-align:middle;
  //   line-height: 1.1;
  // }
  .main-description p {
    margin-bottom: 0px;
    font-weight: bold;
    color: #262262;
  }
  .v-btn__content {
    font-weight: bold;
  }    
</style>
