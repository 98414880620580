<template>
    <v-container fluid class="white--text important-links pa-0">
        <v-container>
            <v-row justify="space-between" dense >
                <v-col cols="6" sm="6" style="display: flex; align-items:center;" >                    
                    <strong><a :href="$t('footer.moreInformationPdf')" class="no-decoration white--text" target="_blank">{{ $t('footer.moreInformation') }}</a></strong>
                    
                </v-col>
                <v-col cols="6" sm="6" class="text-right">                    
                    <strong><a :href="$t('footer.privacyPolicyPdf')" class="no-decoration white--text" target="_blank">{{ $t('footer.privacyPolicy') }}</a></strong>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    export default {
        name: 'ImportantLinks'
    }
</script>

<style lang="scss">
    .no-decoration {
        text-decoration: none;
    }
    .important-links {
        background-color: #ee2a7b;
        font-size: 14px;
        h4 {
            font-weight: 600;
            text-transform: uppercase;
            font-size: 14px;
        }
    }
</style>
