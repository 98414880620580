<template>
  <div class="product-detail">
    <v-skeleton-loader type="article" v-if="loadingCategories || !currentProduct"></v-skeleton-loader>
    <div v-else>
      <h1>{{ currentProduct.name }}</h1>
      <h3>{{ currentProduct.shortDescription }}</h3>

      <p v-html="currentProduct.description" class="product-description mt-3 mb-6"></p>
      <p class="price primary--text font-weight-bold mt-4 mb-0 ">
        <strike v-if="!!currentProduct.basePrice" class="mr-2">
          {{
            $t("homepage.currency", {
              price: formatThousands(currentProduct.basePrice),
            })
          }}
        </strike>
        {{
          $t("homepage.currency", {
            price: formatThousands(currentProduct.amountWithVat),
          })
        }}
      </p>
      <p v-if="!!currentProduct.basePrice" class="mb-0"><small>
          {{
            $t("product.discountNote", {
              price:
                $t("homepage.currency", {
                  price: formatThousands(currentProduct.basePrice - currentProduct.amountWithVat),
                })
            })
          }}</small>
      </p>
      <v-layout class="mb-3 mt-6">
        <vue-numeric-input v-model="qty" :min="1" :max="10" align="center" size="100px" class="ma-0"></vue-numeric-input>
        <v-btn class="ml-3" color="primary" tile depressed @click="addToCart({ product: currentProduct, qty: qty })">{{
          $t("cart.addToCart") }}</v-btn>
      </v-layout>
    </div>
  </div>
</template>

<script>
import VueNumericInput from "vue-numeric-input";
import { mapGetters, mapActions } from "vuex";
import { formatThousands } from "@/Helper";

export default {
  name: "ProductDetail",
  mixins: [formatThousands],
  computed: {
    ...mapGetters(["currentProduct", "currentCategory", "loadingCategories"]),
  },
  components: {
    VueNumericInput,
  },
  data() {
    return {
      qty: 1,
    };
  },
  methods: {
    ...mapActions(["addToCart"]),
  },
};
</script>

<style lang="scss">
.product-detail {
  .product-description {
    span {
      display: block;
      color: #777;

      &:first-child {
        margin-bottom: 20px;
        color: #000000;
      }
    }
  }

  .price {
    font-size: 22px;
  }

  .vue-numeric-input {
    input {
      outline: none;
      height: 100%;
    }

    .btn {
      background: transparent;
      box-shadow: none;
      border: none;

      &:hover {
        background: #fa3600;
      }
    }
  }
}
</style>
