<template>
  <v-container class="footer-address white--text">
    <v-row align="center" justify="center">
      <v-col cols="6" sm="6">
        <p class="mb-0">Zoo Liberec, příspěvková organizace</p>
        <p class="mb-0">Lidové sady 425/1</p>
        <p class="mb-0">460 01 Liberec</p>
      </v-col>
      <v-col cols="6" sm="6" class="text-right">
        <a href="https://elpass.zooliberec.cz" class="no-decoration white--text mr-2" target="_blank"><img
            style="vertical-align:middle" :src="require('../../assets/web_ikona.svg')" width="24" /></a>
        <a href="https://www.facebook.com/zooliberec" class="no-decoration white--text" target="_blank">
          <img style="vertical-align:middle" :src="require('../../assets/facebook_ikona.svg')" width="24" /></a>
      </v-col>
    </v-row>
    <v-row><v-col class="pb-0">
        <hr>
      </v-col></v-row>
    <v-row>
      <v-col cols="6" sm="6" class="d-flex"><img class="mr-2" :src="require('../../assets/lk_logo.svg')"
          height="36" />Projekt vznikl pod záštitou Libereckého kraje</v-col>
      <v-col cols="6" sm="6" class="text-right">
        <a href="https://www.tiktok.com/@regionliberec" class="no-decoration white--text mr-2" target="_blank"><img
            style="vertical-align:middle" :src="require('../../assets/ticktock_ikona.svg')" width="24" /></a>
        <a href="https://www.instagram.com/regionliberec" class="no-decoration white--text mr-2" target="_blank"><img
            style="vertical-align:middle" :src="require('../../assets/instagram_ikona.svg')" width="24" /></a>
        <a href="https://www.liberecky-kraj.cz" class="no-decoration white--text mr-2" target="_blank"><img
            style="vertical-align:middle" :src="require('../../assets/web_ikona.svg')" width="24" /></a>
        <a href="https://www.facebook.com/regionliberec" class="no-decoration white--text" target="_blank">
          <img style="vertical-align:middle" :src="require('../../assets/facebook_ikona.svg')" width="24" /></a>   
     </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FooterAddress",
};
</script>

<style lang="scss">
.footer-address {
  p {
    font-weight: normal;
  }
}</style>
